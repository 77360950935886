.wrapper {
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 0 auto;
}

.unity-container {
/*   position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  background-color: #ffffff; */ /* rgb(145, 145, 145); */
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(51, 48, 48);
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  width: 100px;
  height: 10px;
  background-color: rgb(99, 99, 99);
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 10px;
  background-color: rgb(190, 190, 190);
  transition: width 0.5s ease;
}

.unity-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
}

#unity-container {
  position: absolute;
}
#unity-container.unity-desktop {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#unity-container.unity-mobile {
  width: 100%;
  height: 100%;
}
#unity-canvas {
  background: transparent;
}
.unity-mobile #unity-canvas {
  width: 100%;
  height: 100%;
}
#unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
#unity-footer {
  position: relative;
}
.unity-mobile #unity-footer {
  display: none;
}
#unity-build-title {
  float: right;
  margin-right: 10px;
  line-height: 38px;
  font-family: arial;
  font-size: 18px;
}
#unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}
